/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Column from "../components/column"
import Container from "../components/container"
import CTA from "../components/cta"
import Row from "../components/row"
import RichText from "../components/rich-text"
import Text from "../components/text"

const BenefitsModule = ({ bladePatternData }) => {
  return (
    <div
      sx={{
        py: theme => [
          theme.spacing.vertical.md,
          theme.spacing.vertical.md,
          theme.spacing.vertical.lg,
        ],
      }}
    >
      <Container>
        <Row
          customStyle={{
            flexDirection: ["column", "column", "row-reverse"],
          }}
        >
          <Column>
            {/* TODO: this shuld be updated to a carousel*/}
            {bladePatternData.desktopImage ? (
              <img
                src={bladePatternData.desktopImage.file.url}
                sx={{
                  ml: [0, 0, 100],
                  mb: theme => [
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.sm,
                    0,
                  ],
                }}
                alt=""
              />
            ) : null}
          </Column>
          <Column size={[12, 12, 5]}>
            <div>
              {bladePatternData.contentCards.map((basicCard, idx) => (
                <Row
                  key={idx}
                  customStyle={{
                    mb: theme => [
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.md,
                    ],
                  }}
                >
                  <Text type="h3">{basicCard.title}</Text>
                  {basicCard.description ? (
                    <RichText data={basicCard.description} />
                  ) : null}
                </Row>
              ))}
            </div>
            <div>
              {bladePatternData.ctaTitle ? (
                <CTA
                  link={bladePatternData.ctaDestination || "/"}
                  type={"secondary"}
                  linkType="internal"
                >
                  {bladePatternData.ctaTitle}
                </CTA>
              ) : null}
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  )
}

BenefitsModule.propTypes = {
  bladePatternData: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    ctaTitle: PropTypes.string,
    ctaDestination: PropTypes.string,
    description: PropTypes.object,
    desktopImage: PropTypes.object,
    mobileImages: PropTypes.object,
    contentCards: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        ctaTitle: PropTypes.string,
        ctaDestination: PropTypes.object,
        description: PropTypes.object,
        desktopImage: PropTypes.object,
        mobileImages: PropTypes.object,
      })
    ),
  }),
}

export default BenefitsModule
