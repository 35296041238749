/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import BladePatternAccordeon from "./blade-pattern-accordeon"
import BladePatternTabs from "./blade-pattern-tabs"
import Column from "../components/column"
import Container from "../components/container"
import Row from "../components/row"
import Text from "../components/text"

const ProcessModule = ({ bladePatternData }) => {
  return (
    <div
      sx={{
        py: theme => [
          theme.spacing.vertical.sm,
          theme.spacing.vertical.sm,
          theme.spacing.vertical.lg,
        ],
      }}
    >
      <Container>
        <Row>
          <Text type="h2">{bladePatternData.title}</Text>
        </Row>
        <Row>
          <Column
            customStyle={{
              display: ["block", "block", "none"],
              mt: theme => theme.spacing.vertical.sm,
            }}
          >
            <BladePatternAccordeon data={bladePatternData} />
          </Column>
          <Column customStyle={{ display: ["none", "none", "block"] }}>
            <BladePatternTabs data={bladePatternData} />
          </Column>
        </Row>
      </Container>
    </div>
  )
}

ProcessModule.propTypes = {
  bladePatternData: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    ctaTitle: PropTypes.string,
    ctaDestination: PropTypes.string,
    description: PropTypes.object,
    desktopImage: PropTypes.object,
    mobileImages: PropTypes.object,
    contentCards: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        ctaTitle: PropTypes.string,
        ctaDestination: PropTypes.object,
        description: PropTypes.object,
        desktopImage: PropTypes.object,
        mobileImages: PropTypes.object,
      })
    ),
  }),
}

export default ProcessModule
