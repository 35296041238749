/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { renderLineBreak } from "../helpers"

import Container from "./container"
import Column from "./column"
import CTA from "./cta"
import Row from "./row"
import Text from "./text"

const CTAsModule = ({ bladePatternData, textStyle, ctaType }) => {
  return (
    <div
      sx={{
        py: theme => [
          theme.spacing.vertical.md,
          theme.spacing.vertical.md,
          theme.spacing.vertical.lg,
        ],
        backgroundColor: theme => theme.colors.accent,
      }}
    >
      <Container>
        <Row>
          <Column>
            <Text type="h2" customStyle={textStyle}>
              {renderLineBreak(bladePatternData.title)}
            </Text>
            <div
              sx={{
                mt: theme => [
                  theme.spacing.vertical.sm,
                  theme.spacing.vertical.sm,
                  theme.spacing.vertical.md,
                ],
              }}
            >
              {bladePatternData.contentCards &&
                bladePatternData.contentCards.map((basicCard, idx) =>
                  basicCard.ctaTitle ? (
                    <CTA
                      link={
                        basicCard.ctaDestination
                          ? basicCard.ctaDestination.ctaDestination
                          : ""
                      }
                      key={idx}
                      type={ctaType}
                      linkType="internal"
                      customStyle={{
                        mr: theme => theme.spacing.horizontal,
                      }}
                    >
                      {basicCard.ctaTitle}
                    </CTA>
                  ) : null
                )}
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  )
}

CTAsModule.defaultProps = {
  ctaType: "secondary",
}

CTAsModule.propTypes = {
  bladePatternData: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    ctaTitle: PropTypes.string,
    ctaDestination: PropTypes.string,
    description: PropTypes.object,
    desktopImage: PropTypes.object,
    mobileImages: PropTypes.object,
    contentCards: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        ctaTitle: PropTypes.string,
        ctaDestination: PropTypes.object,
        description: PropTypes.object,
        desktopImage: PropTypes.object,
        mobileImages: PropTypes.object,
      })
    ),
  }),
}

export default CTAsModule
