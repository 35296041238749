/** @jsx jsx */
import { jsx } from "theme-ui"
import { Flex } from "@theme-ui/components"

import { renderLineBreak } from "../helpers"

import Column from "../components/column"
import Container from "../components/container"
import CTA from "../components/cta"
import Carousel from "../components/carousel"
import ProjectCard from "../components/project-card"
import PageLink from "../components/page-link"
import Row from "../components/row"
import Text from "../components/text"

const ProjectGalleryModule = ({ bladePatternData, projectsData }) => {
  const renderProjectsCards = () =>
    projectsData.edges.map((project, idx) => (
      <Column
        size={[12, 12, 4]}
        customStyle={{
          mb: theme => [theme.spacing.vertical.sm, 0],
        }}
        key={idx}
      >
        <PageLink link={`/projects/${project.node.slug}`} type="internal">
          <ProjectCard
            key={idx}
            text={[project.node.projectTitle.projectTitle]}
            background={project.node.projectImages[0].fluid.src}
          />
        </PageLink>
      </Column>
    ))

  return (
    <div
      sx={{
        py: theme => [
          theme.spacing.vertical.md,
          theme.spacing.vertical.md,
          theme.spacing.vertical.lg,
        ],
        backgroundColor: theme => theme.colors.backgrounds.primary,
      }}
    >
      <Container>
        <Row
          customStyle={{
            mb: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
              0,
            ],
          }}
        >
          <Column
            size={[12, 12, 6]}
            customStyle={{
              mb: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.sm,
                theme.spacing.vertical.md,
              ],
            }}
          >
            <Text type="h2">{renderLineBreak(bladePatternData.title)}</Text>
          </Column>
          <Column size={[12, 12, 6]}>
            <Flex
              sx={{
                justifyContent: ["flex-start", "flex-start", "flex-end"],
              }}
            >
              <div>
                {bladePatternData.ctaTitle ? (
                  <CTA
                    link={bladePatternData.ctaDestination || "/"}
                    type={"secondary"}
                    linkType="internal"
                  >
                    {bladePatternData.ctaTitle}
                  </CTA>
                ) : null}
              </div>
            </Flex>
          </Column>
        </Row>

        <Row customStyle={{ display: ["block", "block", "none"] }}>
          <Carousel type="quaternary">{renderProjectsCards()}</Carousel>
        </Row>
        <Row customStyle={{ display: ["none", "none", "flex"] }}>
          {renderProjectsCards()}
        </Row>
      </Container>
    </div>
  )
}

export default ProjectGalleryModule
