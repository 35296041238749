/** @jsx jsx */
import { jsx } from "theme-ui"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import PropTypes from "prop-types"

import Text from "./text"

const RichText = ({ data, textStyle }) => {
  return documentToReactComponents(data.json, {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Text customStyle={textStyle}>{children}</Text>
      ),
    },
  })
}

RichText.propTypes = {
  data: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.arrayOf(
        PropTypes.shape({
          nodeType: PropTypes.string.isRequired,
        })
      ),
    }),
  }),
  textStyle: PropTypes.object,
}

export default RichText
