/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

import Text from "./text"
import RichText from "./rich-text"

const BladePatternAccordeon = ({ data }) => {
  return (
    <Accordion>
      {data.contentCards.map((basicCard, idx) => (
        <AccordionItem
          sx={{
            borderTop: "1px solid black",
            ":last-of-type": {
              borderBottom: "1px solid black",
            },
          }}
          key={idx}
        >
          <AccordionItemHeading>
            <AccordionItemButton
              sx={{
                py: "15px",
              }}
            >
              <Flex
                sx={{
                  alignItems: "center",
                }}
              >
                <Text type="h3">{basicCard.title}</Text>
                {/* TODO: find a way to handle images for improve the use of this component */}
                {basicCard.mobileImage ? (
                  <img
                    src={basicCard.mobileImage.file.url}
                    alt=""
                    sx={{ ml: "auto" }}
                  />
                ) : null}
              </Flex>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <RichText data={basicCard.description} />
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default BladePatternAccordeon
