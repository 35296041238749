/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import QuoteImg from "../../images/quote.png"

import Container from "../container"
import Text from "../text"

const DesignParnterQuote = ({ name, quote, photo }) => {
  return (
    <Container>
      <Flex
        sx={{
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          sx={{
            mb: theme => theme.spacing.vertical.xs,
          }}
        >
          <img
            src={QuoteImg}
            alt=""
            sx={{
              width: [32, 32, 74],
            }}
          />
        </div>
        <div sx={{ maxWidth: 655 }}>
          {quote.quote &&
            documentToReactComponents(quote.quote.json, {
              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => (
                  <Text
                    customStyle={{
                      mt: theme => [0, theme.spacing.vertical.sm],
                      fontSize: 5,
                    }}
                  >
                    {children}
                  </Text>
                ),
              },
            })}
          <Flex
            sx={{
              alignItems: "center",
              mt: theme => theme.spacing.vertical.sm,
            }}
          >
            <img
              src={photo.file.url}
              alt=""
              sx={{
                borderRadius: "50%",
                width: 74,
              }}
            />
            <Text
              customStyle={{
                fontSize: 3,
                ml: theme => theme.spacing.horizontal,
              }}
            >
              {name}
            </Text>
          </Flex>
        </div>
      </Flex>
    </Container>
  )
}

DesignParnterQuote.propTypes = {
  name: PropTypes.string.isRequired,
  quote: PropTypes.shape({
    quote: PropTypes.shape({
      json: PropTypes.object,
    }),
  }),
  photo: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
}

export default DesignParnterQuote
